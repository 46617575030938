export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 120,
    type: 'number'
  },
  {
    prop: 'name',
    label: 'Khu vực',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'updated_at',
    label: 'Ngày cập nhật',
    minWidth: 120,
    type: 'text'
  }
]
