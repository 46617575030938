<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="shippings" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import shippingSchemas from './shipping-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter
    },
    computed: {
      shippings() {
        let rows = this.$store.state.shippings;
        return dtHelper.filterByRules(rows, this.filterOutput);
      }
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(shippingSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil'
          },
          {
            type: 'error',
            icon: 'nc-icon nc-simple-remove'
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(shippingSchemas)
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchShippings');

      this.$store.dispatch('setPageTitle', 'phí vận chuyển');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Thêm phí vận chuyển',
        action: 'createShipping'
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
</style>
